import { Checkbox, TableRow } from "@mui/material";
import TableCell from "../TableCell";

const JPTColumns = ({isAdmin, hideAmount, pending, toggle, selected, disabled}: any) => {
    return isAdmin? (
        pending?
        <TableRow>
            <TableCell><Checkbox onChange={toggle} checked={selected} disabled={disabled}/></TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Payment ID</TableCell>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Details</TableCell>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Intent</TableCell>
            <TableCell>Account Name</TableCell>
            <TableCell>Account Number</TableCell>
            <TableCell>Bank/Wallet</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Gross</TableCell>
            {!hideAmount && <TableCell>Net</TableCell>}
        </TableRow>:
        <TableRow>
            <TableCell>Client</TableCell>
            <TableCell>Payment ID</TableCell>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Intent</TableCell>
            <TableCell>Account Name</TableCell>
            <TableCell>Account Number</TableCell>
            <TableCell>Bank/Wallet</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Transaction Amount</TableCell>
            {!hideAmount && <TableCell>Amount</TableCell>}
            <TableCell>Status</TableCell>
        </TableRow>
    ):
        <TableRow>
            <TableCell>Reference No.</TableCell>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Client Reference</TableCell>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Intent</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Account Name</TableCell>
            <TableCell>Account Number</TableCell>
            <TableCell>Bank/Wallet</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Transaction Amount</TableCell>
            {!hideAmount && <TableCell>Amount</TableCell>}
            <TableCell>Status</TableCell>
        </TableRow>
}

export default JPTColumns;