import { Checkbox, TableRow } from "@mui/material";
import TableCell from "../TableCell";

const JPTRow = ({item, isAdmin, hideAmount, amountFormat, computeAmount, pending, toggle, disabled}: any) => {
    return (
        isAdmin? (
            pending?
            <TableRow hover>
                <TableCell><Checkbox onChange={toggle} checked={!!item.checked} disabled={disabled}/></TableCell>
                <TableCell>{item.receiver_details?.partner_info?.username}</TableCell>
                <TableCell>{item.payment_id}</TableCell>
                <TableCell>{item.additional_details?.client_info?.allbank?.disbursement_details?.tranID}</TableCell>
                <TableCell style={{textTransform: 'capitalize'}}>{item.status?.replace('_', ' ')}</TableCell>
                <TableCell>{
                    item.additional_details?.client_info?.allbank?.disbursement_details?.result_message ||
                    item.additional_details?.client_info?.allbank?.disbursement_details?.message
                }</TableCell>
                <TableCell>{item.updated_at}</TableCell>
                <TableCell style={{textTransform: 'capitalize'}}>{item.intent?.replace('_', ' ')}</TableCell>
                <TableCell>{item.receiver_details?.bank_details?.account_name}</TableCell>
                <TableCell>{item.receiver_details?.bank_details?.account_number}</TableCell>
                <TableCell>{item.receiver_details?.bank_details?.name || item.receiver_details?.bank_details?.bank}</TableCell>
                <TableCell>{item.amount_details?.currency}</TableCell>
                <TableCell>{amountFormat(item.amount_details?.gross)}</TableCell>
                {!hideAmount && <TableCell>{amountFormat(item.amount_details?.net)}</TableCell>}
            </TableRow>:
            <TableRow hover>
                <TableCell>{item.receiver_details?.partner_info?.username}</TableCell>
                <TableCell>{item.payment_id}</TableCell>
                <TableCell>{item.additional_details?.client_info?.allbank?.disbursement_details?.tranID}</TableCell>
                <TableCell>{item.updated_at}</TableCell>
                <TableCell style={{textTransform: 'capitalize'}}>{item.intent?.replace('_', ' ')}</TableCell>
                <TableCell>{item.receiver_details?.bank_details?.account_name}</TableCell>
                <TableCell>{item.receiver_details?.bank_details?.account_number}</TableCell>
                <TableCell>{item.receiver_details?.bank_details?.name || item.receiver_details?.bank_details?.bank}</TableCell>
                <TableCell>{item.amount_details?.currency}</TableCell>
                <TableCell>{amountFormat(item.amount_details?.gross)}</TableCell>
                {!hideAmount && <TableCell>{amountFormat(item.amount_details?.net)}</TableCell>}
                <TableCell style={{textTransform: 'capitalize'}}>{item.status?.replace('_', ' ')}</TableCell>
            </TableRow>
        ):
            <TableRow hover>
                <TableCell>{item.payment_id}</TableCell>
                <TableCell>{item.additional_details?.client_info?.allbank?.disbursement_details?.tranID}</TableCell>
                <TableCell>{item.additional_details?.third_party_details?.client_reference}</TableCell>
                <TableCell>{item.updated_at}</TableCell>
                <TableCell style={{textTransform: 'capitalize'}}>{item.intent?.replace('_', ' ')}</TableCell>
                <TableCell>{item.receiver_details?.bank_details?.account_name}</TableCell>
                <TableCell>{item.receiver_details?.bank_details?.account_number}</TableCell>
                <TableCell>{item.receiver_details?.bank_details?.name || item.receiver_details?.bank_details?.bank}</TableCell>
                <TableCell>{item.amount_details?.currency}</TableCell>
                <TableCell>{amountFormat(item.amount_details?.gross)}</TableCell>
                {!hideAmount && <TableCell>{amountFormat(item.amount_details?.net)}</TableCell>}
                <TableCell style={{textTransform: 'capitalize'}}>{item.status?.replace('_', ' ')}</TableCell>
            </TableRow>
    )
}

export default JPTRow;